<template>
    <div class="mt-3 px-xl-5">
        <h6 class="page-title pb-2 mb-3">TRÁMITES</h6>

        <div v-if="allowStartProcedure" class="text-end mb-2">
            <b-button class="main-btn" @click="goStartProcedure">
                <b-icon-file-earmark-plus />
            </b-button>
        </div>
        
        <component :is="proceduresComponent" />
    </div>
</template>

<script>
import { mapGetters } from "vuex";

import OfficialsProcedures from "./official/OfficialProcedures.vue";
import SuperintendenceProcedures from "./superintendence/SuperintendenceProcedures.vue";

export default {
    name: "procedures",
    data() {
        return {};
    },
    computed: {
        ...mapGetters("application", [
            "isSuperintendence",
            "isOfficial",
            "allowOfficialStartProcedure",
        ]),
        allowStartProcedure() {
            return this.allowOfficialStartProcedure;
        },
        proceduresComponent() {
            if (this.isSuperintendence) {
                return SuperintendenceProcedures;
            }
            if (this.isOfficial) {
                return OfficialsProcedures;
            }
            return null;
        },
    },
    methods: {
        goStartProcedure() {
            this.$router.push({
                name: "procedures-start",
            });
        },
    },
};
</script>
