<template>
    <b-tabs fill content-class="mt-3">
        <b-tab title="Pendientes" active>
            <OnCompetentAuthorityProcedures />
        </b-tab>
        <b-tab title="En Superintendencia">
            <PendingSuperintendenceProcedures />
        </b-tab>
        <b-tab title="Completados">
            <ClosedProcedures />
        </b-tab>
        <b-tab title="Cancelados">
            <CanceledProcedures />
        </b-tab>
    </b-tabs>
</template>

<script>
import PendingSuperintendenceProcedures from "../tables/PendingSuperintendenceProcedures.vue";
import OnCompetentAuthorityProcedures from "../tables/OnCompetentAuthorityProcedures.vue";
import ClosedProcedures from "../tables/ClosedProcedures.vue";
import CanceledProcedures from "../tables/CanceledProcedures.vue";

export default {
    name: "official-procedures",
    components: {
        PendingSuperintendenceProcedures,
        OnCompetentAuthorityProcedures,
        ClosedProcedures,
        CanceledProcedures,
    },
};
</script>