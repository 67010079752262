<template>
    <div>
        <b-card no-header no-body class="rounded-0 shadow-sm border-0">
            <b-table
                stacked="md"
                small
                hover
                show-empty
                empty-text="No hay procesos que mostrar"
                :filter="filter"
                :busy.sync="isBusy"
                :fields="fields"
                :items="retrieveData"
                :current-page="currentPage"
                :per-page="take"
                tbody-tr-class="cs-pointer"
                @filtered="onFiltered"
                @row-clicked="goProcedure"
            >
                <template #table-busy>
                    <div class="text-center text-primary my-2">
                        <b-spinner class="align-middle"></b-spinner>
                    </div>
                    <div class="text-center text-primary my-2">
                        <strong>Cargando procesos ...</strong>
                    </div>
                </template>
                <template #cell(official)="data">
                    {{ data.item.official.name }}
                </template>
                <template #cell(competentAuthorithy)="data">
                    {{ data.item.official.competentAuthority.name }}
                </template>
            </b-table>
        </b-card>
        <b-row class="mb-5 mt-4">
            <b-col>
                <b-pagination
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="take"
                    size="sm"
                    class="my-0 justify-content-end"
                >
                    <template #first-text>
                        <span><b-icon-chevron-double-left scale="1.3" /></span>
                    </template>
                    <template #prev-text>
                        <span><b-icon-chevron-left scale="1.3" /></span>
                    </template>
                    <template #next-text>
                        <span><b-icon-chevron-right scale="1.3" /></span>
                    </template>
                    <template #last-text>
                        <span><b-icon-chevron-double-right scale="1.3" /></span>
                    </template>
                </b-pagination>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import moment from "moment";
import { RepositoryFactory } from "@/services/api";
import { getFinalBeneficiaryStatus } from "@/config/procedures";

export default {
    name: "canceled-procedures",
    data() {
        return {
            isBusy: false,
            fields: [
                { key: "code", label: "Código" },
                { key: "startDate", label: "Fecha petición" },
                { key: "status", label: "Estado" },
                { key: "official", label: "Solicitante" },
                { key: "competentAuthorithy", label: "Autoridad Competente" },
                { key: "description", label: "Asunto" },
                { key: "folios", label: "Números de registro" },
            ],
            filter: null,
            rows: 0,
            currentPage: 1,
            take: 10,
        };
    },
    methods: {
        filterTable() {
            this.filter = this.search;
        },
        onFiltered(filteredItems) {
            this.rows = filteredItems.length;
            this.currentPage = 1;
        },
        async retrieveData(context) {
            this.isBusy = true;
            try {
                let payload = {
                    type: 3,
                    page: context.currentPage,
                    take: this.take,
                    search: context.filter,
                };

                let response =
                    await RepositoryFactory.procedure.getPagedFinalBeneficiaryProcedures(
                        payload
                    );

                this.rows = response.data.total;
                let items = response.data.items;

                for (const item of items) {
                    item.startDate = this.parseAndConvertDate(item.startDate);
                    item.status = this.parseStatus(item.status);
                }

                return items;
            } catch (error) {
                window.console.log(error);
            }
            this.isBusy = false;
        },
        parseAndConvertDate(date) {
            return moment.utc(date).local().format("DD/MM/YYYY HH:mm:ss");
        },
        parseStatus(status) {
            return getFinalBeneficiaryStatus(status);
        },
        goProcedure(procedure) {
            this.$router.push({
                name: "procedure",
                params: { id: procedure.id },
            });
        },
    },
};
</script>
