<template>
    <Procedures />
</template>

<script>
import Procedures from "@/components/procedures/Procedures.vue";

export default {
    name: "procedures",
    components: {
        Procedures,
    },
};
</script>