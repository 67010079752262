<template>
    <b-tabs fill content-class="mt-3">
        <b-tab title="Pendientes" active @click="tabClickHandler(0)">
            <PendingSuperintendenceProcedures v-if="currentTab === 0" />
        </b-tab>
        <b-tab title="Aprobados" @click="tabClickHandler(1)">
            <OnCompetentAuthorityProcedures v-if="currentTab === 1" />
        </b-tab>
        <b-tab title="Completados" @click="tabClickHandler(2)">
            <ClosedProcedures v-if="currentTab === 2" />
        </b-tab>
        <b-tab title="Cancelados" @click="tabClickHandler(3)">
            <CanceledProcedures v-if="currentTab === 3" />
        </b-tab>
    </b-tabs>
</template>

<script>
import PendingSuperintendenceProcedures from "../tables/PendingSuperintendenceProcedures.vue";
import OnCompetentAuthorityProcedures from "../tables/OnCompetentAuthorityProcedures.vue";
import ClosedProcedures from "../tables/ClosedProcedures.vue";
import CanceledProcedures from "../tables/CanceledProcedures.vue";

export default {
    name: "superintendence-procedures",
    components: {
        PendingSuperintendenceProcedures,
        OnCompetentAuthorityProcedures,
        ClosedProcedures,
        CanceledProcedures,
    },
    data() {
        return {
            currentTab: 0
        };
    },
    methods: {
        tabClickHandler(currentTab) {
            this.currentTab = currentTab;
        }
    }
};
</script>